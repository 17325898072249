/* eslint-disable @typescript-eslint/no-explicit-any */
import { httpApi } from '@app/api/http.api';

// POST: Add a new retail price audit report
export const ImportMarketreport = (report: FormData): Promise<undefined> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return httpApi.post<undefined>('import-image', report, config).then(({ data }) => data);
};

export const getImages = (): Promise<any[]> => httpApi.get<any[]>(`import-image/get-images`).then(({ data }) => data);
