import { httpApi } from '@app/api/http.api';
interface response {
  logoValue: string;
}

export const EditLogoChange = (formData: any): Promise<Response> => {
  return httpApi.put<Response>(`/logo`, formData).then(({ data }) => data);
};
export const EditFaviconChange = (formData: any): Promise<Response> => {
  return httpApi.put<Response>(`/logo/favicon`, formData).then(({ data }) => data);
};
export const GetLogoChange = (): Promise<response> => {
  return httpApi.get<response>(`/logo`).then(({ data }) => data);
};
