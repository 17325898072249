/* eslint-disable @typescript-eslint/no-explicit-any */
import { httpApi } from '@app/api/http.api';

interface MarketFootageReport {
  market_id: number;
  retailer_id: number;
  city: string;
  state: string;
  marketImage?: File[]; // Assuming an array of images or videos
}

interface Country {
  id: number;
  name: string;
  // Add other properties as needed
}

interface Retailer {
  id: number;
  name: string;
  // Add other properties as needed
}
// GET: Retrieve market footage reports with filtering options
export const getFootageReports = (): Promise<MarketFootageReport[]> =>
  httpApi.get<MarketFootageReport[]>(`marketFootage`).then(({ data }) => data);

// POST: Add a new market footage report
export const addMarketFootageReport = (report: FormData): Promise<undefined> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return httpApi.post<undefined>('marketFootage/marketFootageAdd', report, config).then(({ data }) => data);
};

// PUT: Update an existing market footage report
export const updateMarketFootageReport = (reportId: string, report: MarketFootageReport): Promise<undefined> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return httpApi
    .put<undefined>(`marketFootage/${reportId}/marketFootageUpdate`, report, config)
    .then(({ data }) => data);
};

// DELETE: Delete an existing market footage report
export const deleteMarketFootageReport = (reportId: string): Promise<undefined> =>
  httpApi.delete<undefined>(`marketFootage/${reportId}/marketFootageDelete`).then(({ data }) => data);

// DELETE: Delete an existing market footage report date wise
export const dateWiseDeleteMarketFootageReport = (marketId: string, date: string): Promise<undefined> =>
  httpApi.delete<undefined>(`marketFootage/dateWiseDelete?market_id=${marketId}&date=${date}`).then(({ data }) => data);

// GET: Retrieve market footage reports with filtering options
export const filterMarketFootageReports = (
  marketId: string,
  retailerId: string,
  date: string,
  page: number,
  pageSize: number,
): Promise<MarketFootageReport[]> =>
  httpApi
    .get<MarketFootageReport[]>(
      `marketFootage?market_id=${marketId}&retailer_id=${
        retailerId === 'Retailer' ? '' : retailerId
      }&date=${date}&page=${page}&page_size=${pageSize}`,
    )
    .then(({ data }) => data);

// GET: Retrieve a list of countries
export const getCountries = (): Promise<Country[]> => httpApi.get<Country[]>('countries').then(({ data }) => data);

export const getcountryList = (): Promise<Country[]> =>
  httpApi.get<Country[]>('countries/countryList ').then(({ data }) => data);
// GET: Retrieve a list of retailers
export const getRetailers = (country_id: string, type: string): Promise<Retailer[]> =>
  httpApi.get<Retailer[]>(`retailers?country_id=${country_id}&type=${type}`).then(({ data }) => data);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const categoryType = (): Promise<any[]> => httpApi.get<Country[]>('categoryType').then(({ data }) => data);

export const MaketfootageDate = (countryID: string, retailer_id: string): Promise<any[]> =>
  httpApi
    .get<any[]>(
      `marketFootage/marketFootageDate?market_id=${countryID}&retailer_id=${
        retailer_id === 'Retailer' ? '' : retailer_id
      }`,
    )
    .then(({ data }) => data);
