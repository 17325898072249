/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { UserSSO } from '@app/api/UserLogin/userlogin.api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { deletePermission, deleteToken, deleteUser, persistToken } from '@app/services/localStorage.service';
import { useDispatch } from 'react-redux';
import { setUser } from '@app/store/slices/userSlice';
import axios from 'axios';
import { GlobalSpinner } from '../common/GlobalSpinner/GlobalSpinner';
import { themeObject } from '@app/styles/themes/themeVariables';
import { notificationController } from '@app/controllers/notificationController';

const SpinnerSSO = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email: string = searchParams.get('email') as string;
  const firstName: string = searchParams.get('firstName') as string;
  const lastName: string = searchParams.get('lastName') as string;
  const BPRStatus: string = searchParams.get('BPRStatus') as string;
  const phone: string = searchParams.get('phone') as string;

  const handlePermisson = async (userId: string, token: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_EC_URL}/assignPagePermission/${userId}/2`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      localStorage.setItem('permissions', JSON.stringify(response.data.data));
    } catch (err) {
      console.log(err);
    }
  };
  const validateInputs = () => {
    // Email validation regex (basic format check)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameRegex = /^[a-zA-Z\- ]+$/;

    if (!email || !emailRegex.test(email)) {
      return false;
    }

    // First name and last name should not be empty
    if (!firstName || !nameRegex.test(firstName) || !lastName || !nameRegex.test(lastName)) {
      return false;
    }

    // Additional validation logic can be added here

    return true;
  };

  const userLogin = async () => {
    if (validateInputs() && BPRStatus.toLowerCase() === 'true') {
      try {
        const login = await UserSSO(email, firstName, lastName, BPRStatus, phone ?? '');
        dispatch(setUser(login?.data?.user));
        persistToken(login?.data?.token);
        localStorage.removeItem('permissions');
        await handlePermisson(login?.data?.user?.id, login?.data?.token);
        const permissionFromLocalStorage = localStorage.getItem('permissions');
        if (permissionFromLocalStorage) {
          navigate('/dashboard/home');
        } else {
          deleteToken();
          deleteUser();
          deletePermission();
          dispatch(setUser(null));
          notificationController.error({ message: 'No permissions found' });
        }
      } catch (error) {
        console.error(error);
      }
    } else if (validateInputs() && BPRStatus.toLowerCase() === 'false') {
      deleteToken();
      deleteUser();
      deletePermission();
      dispatch(setUser(null));
      navigate('/loginFailed');
    } else {
      navigate('/');
    }
  };
  useEffect(() => {
    userLogin();
  }, []);
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <GlobalSpinner color={themeObject['light'].spinnerBase} />
    </div>
  );
};
export default SpinnerSSO;
