import styled from 'styled-components';
import { Modal } from 'antd';
export const InfoHeader = styled.div`
  margin-bottom: 1rem;
`;

export const InfoFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PopupModals = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .ant-card {
    margin: 0;
    overflow: visible;
  }
  .ant-card-body img {
    height: 100%;
    animation: none !important;
  }
  .ant-typography {
    animation: none !important;
    opacity: 1;
    pointer-events: auto;
  }
  .ant-card:hover {
    background: #fff;
  }
  .ant-card:hover .ant-typography {
    color: #13264d;
    opacity: 1;
    pointer-events: auto;
  }
  .ant-card:hover .ant-card-body span {
    color: #404040;
    opacity: 1;
    pointer-events: auto;
  }
`;
