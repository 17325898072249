import React from 'react';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
// import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
// import { BASE_COLORS } from '@app/styles/themes/constants';
import { UserOutlined } from '@ant-design/icons';

export const ProfileDropdown: React.FC = () => {
  // const { isTablet } = useResponsive();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const user = useAppSelector((state: any) => state.user.user);
  // const customStyles = {
  //   backgroundColor: '#f0f0f0',
  //   color: '#333',
  //   border: '1px solid #ccc',
  // };

  return user ? (
    <BasePopover
      content={<ProfileOverlay />}
      trigger="click"
      overlayClassName="custom-popover"
      // overlayStyle={customStyles}
      // overlayInnerStyle={{
      //   backgroundColor: '#f0f0f0',
      //   color: '#333',
      // }}
    >
      <S.ProfileDropdownHeader
        as={BaseRow}
        gutter={[10, 10]}
        align="middle"
        // style={{
        //   position: 'relative',
        // }}
      >
        <BaseCol>
          {/* <BaseAvatar size={45} style={{ backgroundColor: BASE_COLORS.red }}>
            {user.role === 'ADMIN' ? 'A' : 'C'}
          </BaseAvatar> */}
          <BaseAvatar size={44} icon={<UserOutlined />} />
          {/* <BaseAvatar src={user.imgUrl} alt="User" shape="circle" size={40} /> */}
        </BaseCol>
        {/* {isTablet && (
          <BaseCol>
            <span>{`${user.firstName} ${user.lastName[0]}`}</span>
            <span>{`${user.role}`}</span>
          </BaseCol>
        )} */}

        <BaseCol>
          {user.role === 'ADMIN' ? <span>{`${user.role}`}</span> : <span>{`${user.firstName} ${user.lastName}`}</span>}
        </BaseCol>
      </S.ProfileDropdownHeader>
    </BasePopover>
  ) : null;
};
