/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Empty, Layout, Row } from 'antd';
import styled from 'styled-components';
import { Button as AntdButton } from 'antd';
import { useAppSelector } from '@app/hooks/reduxHooks';
import PopupModal from '@app/components/Model/PopupModal';
import MediaLibrarytImportModel from './MediaLibrarytImportModel';
import { MarketCard } from './MediaLibrary/MarketCard';
import nodata from 'assets/nodata.svg';
import { useMounted } from '@app/hooks/useMounted';
import { Pagination as AntdPagination } from 'antd';
// import * as S from '@app/components/nft-dashboard/recently-added/RecentlyAddedNft.styles';
import { getImages } from '@app/api/ImportMarketreport.api';

// const { Content } = Layout;
// const { Title } = Typography;

const MediaLibrary: React.FC = () => {
  const ADD = styled(AntdButton)`
    background: #72b114;
    color: white;
    margin: 10px;
    text-align: right;
  `;
  const CardWrapper = styled.div`
    width: 100%;
    margin-bottom: 15px;
  `;
  const [openimport, setOpenimport] = useState(false);
  const user = useAppSelector((state: any) => state.user.user);
  const [nfts, setNfts] = useState<any[]>([]);
  const { isMounted } = useMounted();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleModalimport() {
    setOpenimport(true);
  }

  const fetch = useCallback(() => {
    getImages()
      .then((reports: any) => {
        setNfts(reports.data.reverse());
        if (isMounted.current) {
          // setNfts(reports.data.reverse());
          // setNfts(reports.data);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  }, [isMounted]);
  useEffect(() => {
    fetch();
  }, [fetch]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const cards = useMemo(() => {
    const paginatedNfts = nfts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return {
      mobile:
        nfts.length !== 0 ? (
          nfts.slice(0, 3).map((nft) => <MarketCard key={nft.title} nftItem={nft} fetch={fetch} />)
        ) : (
          <Empty />
        ),
      tablet:
        nfts.length !== 0 ? (
          <>
            {paginatedNfts.map((nft) => (
              <Col sm={24} md={12} lg={8} key={nft.title}>
                <CardWrapper>
                  <MarketCard nftItem={nft} fetch={fetch} />
                </CardWrapper>
              </Col>
            ))}
            {nfts?.length > 0 && (
              <AntdPagination
                style={{ width: '100%', margin: '20px ' }}
                onChange={(page: number) => setCurrentPage(page)}
                current={currentPage}
                defaultCurrent={1}
                total={nfts?.length}
                pageSize={itemsPerPage}
              />
            )}
          </>
        ) : (
          <img src={nodata} alt="nodata" width="25%" style={{ margin: '29px auto' }} />
        ),
    };
  }, [nfts, currentPage, fetch, CardWrapper]);
  return (
    <Layout style={{ padding: '24px', background: '#fff' }}>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          {user?.role === 'ADMIN' && (
            <>
              <ADD type="primary" onClick={handleModalimport}>
                Import
              </ADD>
            </>
          )}
          <PopupModal
            open={openimport}
            setIsModalOpen={setOpenimport}
            content={
              <div style={{ padding: '40px' }}>
                <MediaLibrarytImportModel fetch={fetch} setIsModalOpen={setOpenimport} />
              </div>
            }
          />
        </Col>
        {cards.tablet}
      </Row>
    </Layout>
  );
};

export default MediaLibrary;
