import React, { useEffect, useRef } from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const PrivatePolicy: React.FC = () => {
  const layoutRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const scrollToTop = () => {
      if (layoutRef.current) {
        layoutRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start', // Ensure the top of the element is aligned with the top of the viewport
        });
        window.scrollBy(0, -450);
      }
    };

    // Scroll to top when component mounts
    scrollToTop();
  }, []);
  return (
    <Layout style={{ padding: '24px', background: '#fff' }}>
      <div ref={layoutRef}>
        <Content style={{ maxWidth: '800px', margin: '0 auto' }}>
          <Title level={2}>PRIVACY POLICY</Title>
          <Title level={5}>1. We respect your privacy</Title>
          <Paragraph>
            Avocados Australia and Export Connect respects your right to privacy and is committed to safeguarding the
            privacy of our customers and website visitors. This policy sets out how we collect and treat your personal
            information. We adhere to the Australian Privacy Principles contained in the Privacy Act 1988 (Cth) and to
            the extent applicable, the EU General Data Protection Regulation (GDPR). Personal information is information
            we hold which is identifiable as being about you. This includes information such as your name, email
            address, identification number, or any other type of information that can reasonably identify an individual,
            either directly or indirectly. You may contact us in writing at 30 Willoughby St, Kirribilli, New South
            Wales, 2061 for further information about this Privacy Policy.
          </Paragraph>
          <Title level={5}>2. What personal information is collected</Title>
          <Paragraph>
            Avocados Australia and Export Connect will, from time to time, receive and store personal information you
            submit to our website, provided to us directly or given to us in other forms. You may provide basic
            information such as your name, phone number, address and email address to enable us to send you information,
            provide updates and process your product or service order. We may collect additional information at other
            times, including but not limited to, when you provide feedback, when you provide information about your
            personal or business affairs, change your content or email preference, respond to surveys and/or promotions,
            provide financial or credit card information, or communicate with our customer support. Additionally, we may
            also collect any other information you provide while interacting with us.
          </Paragraph>
          <Title level={5}>3. How we collect your personal information</Title>
          <Paragraph>
            Avocados Australia and Export Connect collects personal information from you in a variety of ways, including
            when you interact with us electronically or in person when you access our website and when we engage in
            business activities with you. We may receive personal information from third parties. If we do, we will
            protect it as set out in this Privacy Policy. By providing us with personal information, you consent to the
            supply of that information subject to the terms of this Privacy Policy.
          </Paragraph>
          <Title level={5}>4. How we use your personal information</Title>
          <Paragraph>
            Avocados Australia and Export Connect may use personal information collected from you to provide you with
            information about our products or services. We may also make you aware of new and additional products,
            services and opportunities available to you. Avocados Australia and Export Connect will use personal
            information only for the purposes that you consent to. This may include to:<br></br>
            (i) provide you with products and services during the usual course of our business activities;<br></br>
            (ii) administer our business activities;<br></br>
            (iii) manage, research and develop our products and services;<br></br>
            (iv) provide you with information about our products and services<br></br>
            (v) communicate with you by a variety of measures including, but not limited to, by telephone, email, SMS or
            mail; and<br></br>
            (vi) investigate any complaints If you withhold your personal information, it may not be possible for us to
            provide you with our products and services or for you to fully access our website.
          </Paragraph>
          <Title level={5}>5. Disclosure of your personal information</Title>
          <Paragraph>
            Avocados Australia and Export Connect may disclose your personal information to any of our employees,
            officers, insurers, professional advisers, agents, suppliers or subcontractors insofar as reasonably
            necessary for the purposes set out in this privacy policy. If we do disclose your personal information to a
            third party, we will protect it in accordance with this privacy policy.
          </Paragraph>
          <Title level={5}>6. General Data Protection Regulation (GDPR) for the European Union (EU)</Title>
          <Paragraph>
            Avocados Australia and Export Connect will comply with the principles of data protection set out in the GDPR
            for the purpose of fairness, transparency and lawful data collection and use. We process your personal
            information as a Processor and/or to the extent that we are a Controller as defined in the GDPR. We must
            establish a lawful basis for processing your personal information. The legal basis for which we collect your
            personal information depends on the data that we collect and how we use it. We will only collect your
            personal information with your express consent for a specific purpose and any data collected will be to the
            extent necessary and not excessive for its purpose. We will keep your data safe and secure. We will also
            process your personal information if it is necessary for our legitimate interests, or to fulfil a
            contractual or legal obligation. We process your personal information if it is necessary to protect your
            life or in a medical situation, it is necessary to carry out a public function, a task of public interest or
            if the function has a clear basis in law. We do not collect or process any personal information from you
            that is considered Sensitive Personal Information under the GDPR, such as personal information relating to
            your sexual orientation or ethnic origin unless we have obtained your explicit consent, or if it is being
            collected subject to and in accordance with the GDPR. You must not provide us with your personal information
            if you are under the age of 16 without the consent of your parent or someone who has parental authority for
            you. We do not knowingly collect or process the personal information of children
          </Paragraph>
          <Title level={5}>7. Your rights under the GDPR</Title>
          <Paragraph>
            If you are an individual residing in the EU, you have certain rights as to how your personal information is
            obtained and used. Avocados Australia and Export Connect complies with your rights under the GDPR as to how
            your personal information is used and controlled if you are an individual residing in the EU Except as
            otherwise provided in the GDPR, you have the following rights:
            <br></br>
            (i) to be informed how your personal information is being used;<br></br>
            (ii) access your personal information (we will provide you with a free copy of it);<br></br>
            (iii) to correct your personal information if it is inaccurate or incomplete;<br></br>
            (iv)to delete your personal information (also known as the right to be forgotten);<br></br>
            (v) to restrict processing of your personal information;<br></br>
            (vi) to retain and reuse your personal information for your own purposes; (vii) to object to your personal
            information being used; <br></br>
            (viii) to object against automated decision making and profiling Please contact us at any time to exercise
            your rights under the GDPR at the contact details in this Privacy Policy We may ask you to verify your
            identity before acting on any of your requests
          </Paragraph>
          <Title level={5}>8. Hosting and International Data Transfers</Title>
          <Paragraph>
            Information that we collect may from time to time be stored, processed in or transferred between parties or
            sites located in countries outside of Australia. These may include, but are not limited to Australia. We and
            our other group companies have offices and/or facilities in Australia. Transfers to each of these countries
            will be protected by appropriate safeguards, these include one or more of the following: the use of standard
            data protection clauses adopted or approved by the European Commission which you can obtain from the
            European Commission Website; the use of binding corporate rules, a copy of which you can obtain from Export
            Connect’s Data Protection Officer. The hosting facilities for our website are situated in Australia.
            Transfers to each of these Countries will be protected by appropriate safeguards, these include one or more
            of the following: the use of standard data protection clauses adopted or approved by the European Commission
            which you can obtain from the European Commission Website; the use of binding corporate rules, a copy of
            which you can obtain from Export Connect’s Data Protection Officer Our Suppliers and Contractors are
            situated in Australia. Transfers to each of these Countries will be protected by appropriate safeguards,
            these include one or more of the following: the use of standard data protection clauses adopted or approved
            by the European Commission which you can obtain from the European Commission Website; the use of binding
            corporate rules, a copy of which you can obtain from Export Connect Portal’s Data Protection Officer. You
            acknowledge that personal data that you submit for publication through our website or services may be
            available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data
            by others.
          </Paragraph>
          <Title level={5}>9. Security of your personal information</Title>
          <Paragraph>
            Avocados Australia and Export Connect are committed to ensuring that the information you provide to us is
            secure. To prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and
            managerial procedures to safeguard and secure information and protect it from misuse, interference, loss and
            unauthorised access, modification and disclosure. Where we employ data processors to process personal
            information on our behalf, we only do so on the basis that such data processors comply with the requirements
            under the GDPR and that have adequate technical measures in place to protect personal information against
            unauthorised use, loss and theft The transmission and exchange of information is carried out at your own
            risk. We cannot guarantee the security of any information that you transmit to us, or receive from us.
            Although we take measures to safeguard against unauthorised disclosures of information, we cannot assure you
            that personal information that we collect will not be disclosed in a manner that is inconsistent with this
            Privacy Policy.
          </Paragraph>
          <Title level={5}>10. Access to your personal information</Title>
          <Paragraph>
            You may request details of personal information that we hold about you in accordance with the provisions of
            the Privacy Act 1988 (Cth), and to the extent applicable the EU GDPR. If you would like a copy of the
            information which we hold about you or believe that any information we hold on you is inaccurate, out of
            date, incomplete, irrelevant or misleading, please email us at service@exportconnectportal.com.au. We
            reserve the right to refuse to provide you with information that we hold about you, in certain circumstances
            set out in the Privacy Act or any other applicable law.
          </Paragraph>
          <Title level={5}>11. Complaints about privacy</Title>
          <Paragraph>
            If you have any complaints about our privacy practices, please feel free to send in details of your
            complaints to service@exportconnect.com.au. We take complaints very seriously and will respond shortly after
            receiving written notice of your complaint.
          </Paragraph>
          <Title level={5}>12. Changes to Privacy Policy</Title>
          <Paragraph>
            Please be aware that we may change this Privacy Policy in the future. We may modify this Policy at any time,
            in our sole discretion and all modifications will be effective immediately upon our posting of the
            modifications on our website or notice board. Please check back from time to time to review our Privacy
            Policy.
          </Paragraph>
          <Title level={5}>13. Website</Title>
          <Paragraph>
            When you visit our website When you come to our website (https://avocado.exportconnectportal.com), we may
            collect certain information such as browser type, operating system, website visited immediately before
            coming to our site, etc. This information is used in an aggregated manner to analyse how people use our
            site, such that we can improve our service.<br></br> <br></br>
            <strong>Cookies</strong>
            <br></br> We may from time to time use cookies on our website. Cookies are very small files which a website
            uses to identify you when you come back to the site and to store details about your use of the site. Cookies
            are not malicious programs that access or damage your computer. Most web browsers automatically accept
            cookies but you can choose to reject cookies by changing your browser settings. However, this may prevent
            you from taking full advantage of our website. Our website may from time to time use cookies to analyses
            website traffic and help us provide a better website visitor experience. In addition, cookies may be used to
            serve relevant ads to website visitors through third party services such as Google AdWords. These ads may
            appear on this website or other websites you visit.
            <br></br> <br></br>
            <strong> Third party sites </strong>
            <br></br>
            Our site may from time to time have links to other websites not owned or controlled by us. These links are
            meant for your convenience only. Links to third party websites do not constitute sponsorship or endorsement
            or approval of these websites. Please be aware that Avocados Australia and Export Connect is not responsible
            for the privacy practises of other such websites. We encourage our users to be aware, when they leave our
            website, to read the privacy statements of each and every website that collects personal identifiable
            information.
          </Paragraph>
        </Content>
      </div>
    </Layout>
  );
};

export default PrivatePolicy;
