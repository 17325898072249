import { httpApi } from '@app/api/http.api';
interface response {
  status: boolean;
  message: string;
  data?: [];
}
export const GetCountry = (): Promise<response> => {
  return httpApi.get<response>(`/countries`).then(({ data }) => data);

  // Handle the case when data is null, perhaps throw an error or handle it appropriately.
};
