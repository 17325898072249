import React, { useEffect, useMemo } from 'react';
// import { Overlay } from '../../../../common/Overlay/Overlay';
import { useResponsive } from 'hooks/useResponsive';
import * as S from './MainSider.styles';
// import { SiderLogo } from '../SiderLogo';
import SiderMenu from '../SiderMenu/SiderMenu';
import SiderPremiumFeaturesMenu from '../SiderMenu/SiderPremiumFeaturesMenu';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/Export_footer.svg';
import { Link } from 'react-router-dom';

interface MainSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const MainSider: React.FC<MainSiderProps> = ({ isCollapsed, setCollapsed, ...props }) => {
  const { isDesktop, mobileOnly, tabletOnly } = useResponsive();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const user = useAppSelector((state: any) => state.user.user);
  const navigate = useNavigate();

  const isCollapsible = useMemo(() => mobileOnly && tabletOnly, [mobileOnly, tabletOnly]);

  // const toggleSider = () => setCollapsed(!isCollapsed);

  useEffect(() => {
    if (user == null) {
      navigate('/auth/login');
    }
  }, [navigate, user]);

  // Use these lines to set collapsed and collapsedWidth properties based on conditions
  let collapsed, collapsedWidth;

  if (isDesktop) {
    collapsed = isCollapsed;
    collapsedWidth = !tabletOnly ? 95 : 0;
  } else {
    collapsed = !isDesktop && isCollapsed;
    collapsedWidth = tabletOnly ? 95 : 0;
  }

  return (
    <div className="content_cus">
      <S.Sider
        trigger={null}
        collapsed={collapsed}
        collapsedWidth={collapsedWidth}
        collapsible={isCollapsible}
        width={260}
        {...props}
      >
        <S.SiderContent>
          {/* <S.SidebarHeading>Your Dashboard</S.SidebarHeading> */}
          <SiderMenu setCollapsed={setCollapsed} />
          {/* <S.SidebarHeading>Premium Features</S.SidebarHeading> */}
          <SiderPremiumFeaturesMenu setCollapsed={setCollapsed} />
        </S.SiderContent>
      </S.Sider>
      {/* {mobileOnly && <Overlay onClick={toggleSider} show={!isCollapsed} />} */}

      {isCollapsed ? (
        <>
          <img src={logo} alt="Lightence" width={100} className="footer_logo" />
          {user?.role != 'ADMIN' && (
            <Link to="dashboard/helpsupport" className="newCustom">
              Contact Us
            </Link>
          )}
        </>
      ) : (
        <>
          <img src={logo} alt="Lightence" width={180} style={{ marginLeft: '33px' }} className="footer_logo" />
          {user?.role != 'ADMIN' && (
            <Link to="dashboard/helpsupport" className="newCustom2">
              Contact Us
            </Link>
          )}
        </>
      )}
    </div>
  );
};

export default MainSider;
