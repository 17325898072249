// import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import React, { useState } from 'react';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import { IoIosHelpCircleOutline } from 'react-icons/io';

interface LoginFormData {
  email: string;
  password: string;
}

export const initValues: LoginFormData = {
  email: 'hello@altence.com',
  password: 'some-test-pass',
};

const ContactUs: React.FC = () => {
  const Container = styled.div`
    width: 50%;
    margin: 0 auto;
    text-align: center;
    @media (max-width: 540px) {
      width: 100%;
    }
  `;

  const Title = styled.h2`
    color: #3c3c3c;
    font-weight: 700;
    font-size: 30px;
  `;

  const ButtonItem = styled.button<{ isActive: boolean }>`
    cursor: pointer;
    width: 100%;
    display: flex;
    text-align: left;
    padding: 20px;
    margin-bottom: 15px;
    border: none;
    justify-content: space-between;
    border-radius: 4px;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    background-color: ${({ isActive }) => (isActive ? '#F0ECC7' : 'transparent')};
    transition: background-color 0.3s;

    &:hover {
      background-color: ${({ isActive }) => (isActive ? '#F0ECC7' : '#eaeaea')};
    }
  `;

  // const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const navigate = useNavigate();
  const handleButtonClick = (option: string) => {
    setSelectedOption(option === selectedOption ? null : option);
  };

  const handleSubmit = () => {
    if (selectedOption) {
      navigate('/dashboard/contactus');
    }
    // Add your form submission logic here
  };

  const FormWrapper = styled(Auth.FormWrapper)`
    padding: 0;
    margin: 30px 0;
    width: 100%;
  `;

  const SubmitButton = styled(Auth.SubmitButton)`
    background: #72b114;
    color: white;
  `;

  return (
    <Container>
      <Title>How can we help?</Title>
      <p>Please select the most relevant option.</p>

      <FormWrapper>
        <div>
          <ButtonItem
            isActive={selectedOption === 'I have a question about the portal'}
            onClick={() => handleButtonClick('I have a question about the portal')}
          >
            <span>I have a question about the portal</span>
            <Tooltip title="Help" placement="bottom">
              <IoIosHelpCircleOutline />
            </Tooltip>
          </ButtonItem>
          <ButtonItem
            isActive={selectedOption === 'I have a question for Avocados Australia'}
            onClick={() => handleButtonClick('I have a question for Avocados Australia')}
          >
            <span>I have a question for Avocados Australia</span>
            <Tooltip title="Help" placement="bottom">
              <IoIosHelpCircleOutline />
            </Tooltip>
          </ButtonItem>
          <ButtonItem
            isActive={selectedOption === 'Something else'}
            onClick={() => handleButtonClick('Something else')}
          >
            <span>Something else</span>
            <Tooltip title="Help" placement="bottom">
              <IoIosHelpCircleOutline />
            </Tooltip>
          </ButtonItem>
        </div>
        <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
          <BaseForm.Item noStyle>
            <SubmitButton type="primary" htmlType="submit">
              Next
            </SubmitButton>
          </BaseForm.Item>
        </BaseForm>
      </FormWrapper>
    </Container>
  );
};

export default ContactUs;
