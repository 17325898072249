import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { activityStatuses } from '@app/constants/config/activityStatuses';
import * as S from './ActivityStoryItem.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { Button } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { setUser } from '@app/store/slices/userSlice';
import { persistToken } from '@app/services/localStorage.service';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { Tooltip } from 'antd';
import { LoginUser } from '@app/api/UserLogin/userlogin.api';
interface ActivityStoryItemProps {
  item: data;
  setshow: React.Dispatch<React.SetStateAction<boolean>>;
  setSelecteditem: (item: data) => void;
}

interface data {
  country_name: string;
  password: any;
  firstName: string;
  lastName: string;
  country: string;
  company: string;
  position: string;
  phoneNumber: number;
  lastActive: string;
  email: string;
  _id: string;
  created: Date;
}
const Impersonate = styled(BaseButton)`
  display: block;
  margin: 10px 0;
`;
export const ActivityStoryItem = ({ item, setshow, setSelecteditem }: ActivityStoryItemProps): JSX.Element => {
  // const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const currentStatus = activityStatuses.find((configStatus) => configStatus.name === status);
  const BaseRowBox = styled(BaseRow)`
    box-shadow: -3px -1px 9px 0px rgba(5, 5, 5, 0.48);
    padding: 20px;
  `;
  const navigate = useNavigate();
  const navigateToDifferentRoute = (id: string) => {
    navigate(`/admin/allPermission/${id}`);
  };
  // const handleImpersonateClick = async (id: string, userdata: any) => {
  //   try {
  //     setLoading(true);
  //     // const data = await LoginUser(id || '', userdata);
  //     const encodedPassword = await encodeURIComponent(userdata.password);
  //     window.location.href =
  //       await `https://avocado.ukitss.com/auth/ecporatallogin/${id}/${userdata.email}/${encodedPassword}`;
  //     // dispatch(setUser(data?.data?.user));
  //     // persistToken(data.data.token);
  //   } catch (error) {
  //     console.error('Error occurred while logging in:', error);
  //     // Handle error here, such as displaying a message to the user
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleImpersonateClick = async (id: string, userdata: any) => {
    try {
      setLoading(true);
      const data = await LoginUser(id || '', userdata);
      dispatch(setUser(data?.data?.user));
      persistToken(data.data.token);
      navigate('/dashboard/home', { state: 'Welcome to avocados' });
    } catch (error) {
      console.error('Error occurred while logging in:', error);
      // Handle error here, such as displaying a message to the user
    } finally {
      setLoading(false);
    }
  };
  return (
    <BaseRowBox wrap={false} align="middle">
      <BaseCol>{/* <img width={80} height={80} src={image} alt={title} /> */}</BaseCol>
      <BaseCol flex={1}>
        <BaseRow justify="space-between" wrap={true}>
          <BaseCol>
            <BaseRow gutter={[6, 6]}>
              <>
                <BaseCol span={24}>
                  <S.Title>Name: {item?.fullName}</S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title>Email: {item?.email}</S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title>Country: {item?.country_name}</S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title>Company: {item?.company}</S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title>Position: {item?.position}</S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title> Role: {item?.role}</S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Title>Phone Number: {item?.phoneNumber}</S.Title>
                </BaseCol>
                <BaseCol span={24}>
                  <S.Status $color={currentStatus?.color || 'primary'}>
                    Created: {item?.created != 'N/A' ? moment(item?.created).format('ddd MMM DD YYYY HH:mm:ss') : 'N/A'}
                  </S.Status>
                </BaseCol>
                {/* <BaseCol span={24}>
                  <Button onClick={() => navigateToDifferentRoute(item._id)}>Login</Button>
                </BaseCol> */}
                <Tooltip title="Direct Login With That User">
                  <Impersonate
                    loading={loading}
                    severity="error"
                    onClick={() =>
                      handleImpersonateClick(item?._id || '', {
                        email: item.email,
                        password: item.password,
                      })
                    }
                  >
                    Login
                  </Impersonate>
                </Tooltip>
              </>
            </BaseRow>
          </BaseCol>
        </BaseRow>
      </BaseCol>
      <div>
        <Button onClick={() => (setshow(false), setSelecteditem(item))}>
          <SettingOutlined />
        </Button>
      </div>
    </BaseRowBox>
  );
};
