/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';
import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import HelpSupport from '@app/pages/HelpSupport';
import ContactUs from '@app/pages/ContactUs';
import { withLoading } from '@app/hocs/withLoading.hoc';
import TermsOfUse from '@app/pages/TermsOfUse/TermsOfUse';
import PrivatePolicy from '@app/pages/PrivatePolicy/PrivatePolicy';
import MediaLibrary from '@app/pages/MediaLibrary/MediaLibrary';
// import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';
import HomePage from '@app/pages/Home/Home';
import ProfileSetting from '@app/pages/ProfileSetting/ProfileSetting';
import AddMarket from '@app/pages/Addmarket/AddMarket';
import EcPortalLogin from '@app/pages/EcPortalLogin';
import { useAppSelector } from '@app/hooks/reduxHooks';
import Admin from '@app/pages/Admin/admin';
import SpinnerSSO from '../spinnerSSO/SpinnerSSO';
// import LandingPage from '@app/pages/Landing/Landing';
const NewsFeedPage = React.lazy(() => import('@app/pages/NewsFeedPage'));
const ReportsPage = React.lazy(() => import('@app/pages/Reports/ReportsPage'));
// const TermsOfUsePage = React.lazy(() => import('@app/pages/TermsOfUse/TermsOfUse'));
// const PrivatePolicyPage = React.lazy(() => import('@app/pages/PrivatePolicy/PrivatePolicy'));
// const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const RetailPriceAuditsPage = React.lazy(() => import('@app/pages/RetailPriceAudits/RetailPriceAuditsPage'));
const CompetitorReviewsPage = React.lazy(() => import('@app/pages/CompetitorReviews/CompetitorReviewsPage'));
const AdvisoryServicePage = React.lazy(() => import('@app/pages/AdvisoryService/AdvisoryServicePage'));
const InMarketFootagePage = React.lazy(() => import('@app/pages/InMarketFootagePage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const LoginFailedPage = React.lazy(() => import('@app/pages/LoginFailedPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));
const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));
const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
const GoogleMaps = React.lazy(() => import('@app/pages/maps/GoogleMapsPage/GoogleMapsPage'));
const LeafletMaps = React.lazy(() => import('@app/pages/maps/LeafletMapsPage/LeafletMapsPage'));
const ReactSimpleMaps = React.lazy(() => import('@app/pages/maps/ReactSimpleMapsPage/ReactSimpleMapsPage'));
const PigeonsMaps = React.lazy(() => import('@app/pages/maps/PigeonsMapsPage/PigeonsMapsPage'));
const Logout = React.lazy(() => import('./Logout'));

export const LANDING_PAGE = '/';
export const DASHBOARD = '/dashboard';

const MediaLibraryPagePage = withLoading(MediaLibrary);
const PrivatePolicyPage = withLoading(PrivatePolicy);
const TermsOfUsePage = withLoading(TermsOfUse);
const Reports = withLoading(ReportsPage);
const AddMarketPage = withLoading(AddMarket);
// const Landing = withLoading(LandingPage);
const Home = withLoading(HomePage);
// const NftDashboard = withLoading(NftDashboardPage);
const NewsFeed = withLoading(NewsFeedPage);
const AdvancedForm = withLoading(AdvancedFormsPage);
const HelpSupportPage = withLoading(HelpSupport);
const ContactUsPage = withLoading(ContactUs);
const ProfileSettingPage = withLoading(ProfileSetting);
// UI Components
const Buttons = withLoading(ButtonsPage);
const Spinners = withLoading(SpinnersPage);
const Inputs = withLoading(InputsPage);
const Checkboxes = withLoading(CheckboxesPage);
const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Switches = withLoading(SwitchesPage);
const Uploads = withLoading(UploadsPage);
const Rates = withLoading(RatesPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const Steps = withLoading(StepsPage);
const DateTimePickers = withLoading(DateTimePickersPage);
const Dropdowns = withLoading(DropdownsPage);
const Breadcrumbs = withLoading(BreadcrumbsPage);
const Tabs = withLoading(TabsPage);
const Avatars = withLoading(AvatarsPage);
const Badges = withLoading(BadgesPage);
const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);
const Modals = withLoading(ModalsPage);
const Popovers = withLoading(PopoversPage);
const Popconfirms = withLoading(PopconfirmsPage);
const Progress = withLoading(ProgressPage);
const Results = withLoading(ResultsPage);
const Alerts = withLoading(AlertsPage);
const NotificationsUI = withLoading(NotificationsUIPage);
const Skeletons = withLoading(SkeletonsPage);

// const DataTables = withLoading(DataTablesPage);
const RetailPriceAudits = withLoading(RetailPriceAuditsPage);
const CompetitorReviews = withLoading(CompetitorReviewsPage);
const AdvisoryService = withLoading(AdvisoryServicePage);
const InMarketFootage = withLoading(InMarketFootagePage);

// Maps
const Google = withLoading(GoogleMaps);
const Leaflet = withLoading(LeafletMaps);
const ReactSimple = withLoading(ReactSimpleMaps);
const Pigeons = withLoading(PigeonsMaps);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);
// const permissionFromLocalStorage = localStorage.getItem('permissions');
// const HomePagePermission = permissionFromLocalStorage
//   ? JSON.parse(permissionFromLocalStorage)?.pagePermissions?.find((item: any) => item.page_name === 'Home')
//   : null;
// const ReportsPagePermission = permissionFromLocalStorage
//   ? JSON.parse(permissionFromLocalStorage)?.pagePermissions?.find((item: any) => item.page_name === 'Reports')
//   : null;
// const RetailPriceAuditsPagePermission = permissionFromLocalStorage
//   ? JSON.parse(permissionFromLocalStorage)?.pagePermissions?.find(
//       (item: any) => item.page_name === 'Retail_Price_Audits',
//     )
//   : null;
// const InMarketFootagePagePermission = permissionFromLocalStorage
//   ? JSON.parse(permissionFromLocalStorage)?.pagePermissions?.find((item: any) => item.page_name === 'In_Market_Footage')
//   : null;

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );
  const user: any = useAppSelector((state) => state?.user?.user);
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path={LANDING_PAGE} element={protectedLayout} /> */}
        <Route path="*" element={<Error404Page />} />]
        <Route path="/loginFailed" element={<LoginFailedPage />} />
        {/* <Route path={YOUR_SUBSCRIPTION} element={protectedLayout}> */}
        <Route path={LANDING_PAGE} element={protectedLayout}>
          {/* <Route index element={<NftDashboard />} /> */}
          <Route index element={<Home />} />
          <Route path="termsofuse" element={<TermsOfUsePage />} />
          <Route path="privacypolicy" element={<PrivatePolicyPage />} />
          {/* {HomePagePermission?.permissions?.show_page && <Route path="dashboard/home" element={<Home />} />} */}
          <Route path="dashboard/home" element={<Home />} />
          {/* {ReportsPagePermission?.permissions?.show_page && <Route path="dashboard/reports" element={<Reports />} />} */}
          <Route path="dashboard/reports" element={<Reports />} />
          <Route path="dashboard/medialibrary" element={<MediaLibraryPagePage />} />
          <Route path="dashboard/apps">
            <Route path="feed" element={<NewsFeed />} />
          </Route>
          <Route path="forms">
            <Route path="advanced-forms" element={<AdvancedForm />} />
          </Route>
          {/* <Route path="data-tables" element={<DataTables />} /> */}
          {user?.role === 'ADMIN' && <Route path="dashboard/admin" element={<Admin />} />}
          {/* {RetailPriceAuditsPagePermission?.permissions?.show_page && (
            <Route path="dashboard/retail-price-audits" element={<RetailPriceAudits />} />
          )} */}
          <Route path="dashboard/retail-price-audits" element={<RetailPriceAudits />} />
          <Route path="dashboard/competitor-reviews" element={<CompetitorReviews />} />
          <Route path="dashboard/advisory-service" element={<AdvisoryService />} />
          {/* {InMarketFootagePagePermission?.permissions?.show_page && (
            <Route path="dashboard/in-market-footage" element={<InMarketFootage />} />
          )} */}
          <Route path="dashboard/in-market-footage" element={<InMarketFootage />} />
          <Route path="dashboard/helpsupport" element={<HelpSupportPage />} />
          <Route path="dashboard/contactus" element={<ContactUsPage />} />
          <Route path="dashboard/add-market" element={<AddMarketPage />} />
          <Route path="maps">
            <Route path="google-maps" element={<Google />} />
            <Route path="leaflet-maps" element={<Leaflet />} />
            <Route path="react-simple-maps" element={<ReactSimple />} />
            <Route path="pigeon-maps" element={<Pigeons />} />
          </Route>
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="dashboard/profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="profilesetting" element={<ProfileSettingPage />} />
            <Route path="security-settings" element={<SecuritySettings />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="payments" element={<Payments />} />
          </Route>
          <Route path="ui-components">
            <Route path="button" element={<Buttons />} />
            <Route path="spinner" element={<Spinners />} />
            <Route path="input" element={<Inputs />} />
            <Route path="checkbox" element={<Checkboxes />} />
            <Route path="radio" element={<Radios />} />
            <Route path="select" element={<Selects />} />
            <Route path="switch" element={<Switches />} />
            <Route path="upload" element={<Uploads />} />
            <Route path="rate" element={<Rates />} />
            <Route path="auto-complete" element={<AutoCompletes />} />
            <Route path="steps" element={<Steps />} />
            <Route path="date-time-picker" element={<DateTimePickers />} />
            <Route path="dropdown" element={<Dropdowns />} />
            <Route path="breadcrumbs" element={<Breadcrumbs />} />
            <Route path="tabs" element={<Tabs />} />
            <Route path="avatar" element={<Avatars />} />
            <Route path="badge" element={<Badges />} />
            <Route path="collapse" element={<Collapse />} />
            <Route path="pagination" element={<Pagination />} />
            <Route path="modal" element={<Modals />} />
            <Route path="popover" element={<Popovers />} />
            <Route path="popconfirm" element={<Popconfirms />} />
            <Route path="progress" element={<Progress />} />
            <Route path="result" element={<Results />} />
            <Route path="alert" element={<Alerts />} />
            <Route path="notification" element={<NotificationsUI />} />
            <Route path="skeleton" element={<Skeletons />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="varifymail" element={<SecurityCodePage />} />
          <Route path="user/sso-login" element={<SpinnerSSO />} />
          <Route path="ecporatallogin/:id/:email/:password" element={<EcPortalLogin />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
