import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const accessToken = localStorage.getItem('accessToken');
  const permission = localStorage.getItem('permissions');
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/dashboard/home') {
      if (accessToken && permission) navigate('/dashboard/home', { state: 'Welcome to avocados' });
    }
  }, [accessToken, permission]);
  const token = localStorage.getItem('accessToken');
  // const token = useAppSelector((state) => state.auth.token);
  return token ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;
