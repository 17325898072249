/* eslint-disable @typescript-eslint/no-explicit-any */
import { httpApi } from '@app/api/http.api';

// GET: dashboardsettingdata
interface data {
  title: string;
  description: string;
  image?: string; // Optional since it's undefined in your example
}
export const getdashboardsetting = (): Promise<undefined[]> =>
  httpApi.get<[undefined]>('dashboardSettings').then(({ data }) => data);

// PUT: Update dashboardsettingdata
export const dashboardsettingUpdate = (settingId: string, data: data): Promise<undefined> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return httpApi
    .post<undefined>(`dashboardSettings/settingsAddUpdate?settingId=${settingId}`, data, config)
    .then(({ data }) => data);
};
